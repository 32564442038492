import { useAction } from '@reatom/react';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { taskApi } from '../../../api';
import { requestPanelActions } from '../../../components';
import { useTranslateData } from '../../../hooks';
import i18n, { LOCALES } from '../../../locales';
import { Button, Headline, Link } from '../../../ui';
import { getImageAsUri } from '../../../utils/getImageAsUri';
import { parseSolution } from '../../../utils/parser-solution';
import { LABELS, MAX_FILE_SIZE } from '../constants';
import { EmptyDND, ExampleFiles, FileList, Passport, Price, Snils } from '../partials';
import { ITask } from '../task';
import { calcSeconds, getDocType, isValidPassportData, isValidSnilsData } from '../utils';
import { PreviewItem, PreviewList, PreviewWrapper } from './elements';
import { ErrorType, NetworkStatus } from './NetworkStatus';
import * as styles from './styles.module.css';

type TranslateData = {
  options: DropzoneOptions;
  statusCode: {
    pending: string;
    completed: string;
  };
  errorCode: IErrorCode;
  description: {
    desktop: {
      text: string;
      link: string;
    };
    mobile: {
      text: string;
      link: string;
    };
  };
  example: {
    images: string[];
    description: string;
  },
  result: {
    recognizeTime: string
    demoTitle: string
    demoPrefix: string
    demoSuffix: string
    document: string
    sendRequest: string
    toSendRequest: string
    price: string
  };
};

const initState: {
  files: any[];
  task: ITask | null;
  isPending: boolean;
  error: ErrorType | null;
  delay: number;
  currentFileId: number;
  currentDocType: string;
  currentFileSrc: string;
} = {
  files: [],
  task: null,
  isPending: false,
  error: null,
  delay: 0,
  currentFileId: 0,
  currentDocType: '',
  currentFileSrc: '',
};
const TASK_FINAL_STATUSES = ['Completed', 'Rejected'];
const CORRECT_DOCUMENTS = ['passport', 'snils'];
let checkResultTimeout: number | null | undefined = null;

export const DND: FC = () => {
  const [parseData, setParseData] = useState<IParseData|null>(null);
  const { options, statusCode, errorCode, description, example, result } =
    useTranslateData<TranslateData>('components.dnd') as TranslateData;
  const [state, setState] = useState(initState);
  const {
    files,
    isPending,
    task,
    error,
    delay,
    currentFileId
  } = state;

  const locale = i18n.language as keyof typeof LOCALES;

  const updateState = useCallback((newStatePartial) => {
    setState((prevState) => ({
      ...prevState,
      ...newStatePartial,
    }));
  }, []);

  const updateDelay = () => {
    const updatedDelay = Math.max(
      Math.ceil(
        (Number(state.error?.delay) -
          (Date.now() - Number(state.error?.timestamp))) /
          1000
      ),
      0
    );
    updateState({ delay: updatedDelay });

    if (updatedDelay > 0) window.setTimeout(updateDelay, 1000);
  };

  const setStateError = (response?: any, code = '') => {
    const today = Date.now();
    updateState({
      isPending: false,
      error: {
        code,
        timestamp: today,
        ...response.data,
        ...(errorCode[code] || errorCode.common),
      },
      delay:
        response.status === 429 ? Math.ceil(response.data.delay / 1000) : 0,
    });
  };

  const setStateTask = useCallback((task?: ITask) => {
    updateState({ error: null, task });
  }, []);

  const handleReset = useCallback(() => {
    setParseData(null);
    setState(initState);
    window.localStorage.removeItem('processTaskId');
    window.clearTimeout(checkResultTimeout as number);
  }, []);

  const openRequestPanelAction = useAction(
    requestPanelActions.openRequestPanelAction
  );
  const handleOpenRequestPanel = useCallback(() => {
    openRequestPanelAction();
  }, [openRequestPanelAction]);

  const handleOnDrop = useCallback(
    (acceptedFiles: File[], fileRejections?: any) => {
      if (fileRejections?.length) {
        const { errors } = fileRejections[0];
        const { code } = errors[0];

        setStateError({}, code);
        return;
      }
      const formData = new FormData();
      formData.append('files', acceptedFiles[0]);
      formData.append('preset', 'landing.recognize');

      const files = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      updateState({ files, error: null, isPending: true });

      taskApi
        .create(formData)
        .then((response) => {
          setStateTask(response.data);
          window.localStorage.setItem('processTaskId', response.data.id);
          checkResult(response.data.id);
        })
        .catch((response) => {
          setStateError(response, response.status);
        });
    },
    [errorCode]
  );

  const checkResult = useCallback((taskId: string) => {
    taskApi
      .getResults(taskId)
      .then((response) => {
        if (TASK_FINAL_STATUSES.includes(response.data.status)) {
          const data = parseSolution(response.data);
          setParseData(data);
          return;
        }
        checkResultTimeout = window.setTimeout(() => {
          checkResult(taskId);
        }, 3000);
      })
      .catch((response) => {
        setStateError(response, response.status);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...options,
    disabled: isPending || Boolean(error),
    onDrop: handleOnDrop,
    maxSize: MAX_FILE_SIZE,
    multiple: false
  });

  const handlePreviewClick = useCallback(
    (e) => {
      const id = e.currentTarget.dataset.id;
      updateState({
        currentFileId: isNaN(id) ? 0 : Number(id),
        currentFileSrc: e.currentTarget.dataset.src,
        currentDocType: getDocType(task, id),
      });
    },
    [task]
  );

  const handleExampleFileClick = useCallback(
    (src) => {
      if (isPending) {
        return;
      }
      getImageAsUri(src, handleOnDrop, []);
    },
    [handleOnDrop, isPending]
  );

  const handleDragStart = useCallback((e, src) => {
    e.dataTransfer.setData('text', src);
  }, []);

  const handleDrop = useCallback(
    (e) => {
      if (isPending) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      try {
        if (e.dataTransfer.types.includes('text/plain')) {
          e.preventDefault();
          e.stopPropagation();
          getImageAsUri(e.dataTransfer.getData('text'), handleOnDrop, []);
        }
      } catch (e) {
        console.error('Typification', 'handleDrop', e);
      }
    },
    [handleOnDrop, isPending]
  );

  useEffect(() => {
    if (Number(state?.error?.code) === 429) {
      window.setTimeout(updateDelay, 1000);
    }
  }, [state.error?.code]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      window.clearTimeout(Number(checkResultTimeout));
    },
    [files]
  );

  useEffect(() => {
    if (window.localStorage.getItem('processTaskId')) {
      updateState({ isPending: true });
      checkResult(window.localStorage.getItem('processTaskId') || '');
    }
  }, []);

  if (TASK_FINAL_STATUSES.includes(parseData?.status || '')) {
    return (
      <div
        className={classNames(
          'm-auto',
          'w-[288px]',
          'xs:w-[330px]',
          'sm:w-full',
          'md:px-[60px]',
          'md:py-[57px]',
          'md:bg-graphite-87',
          'md:rounded-[5px]',
          'relative'
        )}
      >
        <Headline
          size={4}
          className={classNames('mb-[26px]', 'sm:mb-[40px]', 'flex')}
        >
          <span className={classNames(styles.icon, 'bg-orange-60')} />
          {result.recognizeTime.replace(/%seconds%/, calcSeconds(parseData?.createdAt || '', parseData?.finishedAt))}
        </Headline>
        <div
          className={classNames(
            'flex',
            'flex-wrap',
            'justify-center',
            'sm:justify-start',
            'items-start'
          )}
        >
          <div
            className={classNames(
              'flex',
              'flex-col-reverse',
              'lg:flex-row',
              'mb-[30px]',
              'sm:mb-0'
            )}
          >
            {Number(parseData?.result?.length) > 1 && (
              <PreviewWrapper>
                <PreviewList>
                  {(parseData?.result || []).map((fileDetailed, index) =>
                    <PreviewItem
                      key={fileDetailed.image.preview}
                      data-src={fileDetailed.image.preview}
                      data-id={index}
                      onClick={handlePreviewClick}
                      className={classNames(
                        currentFileId === index
                          ? 'cursor-default'
                          : 'cursor-pointer',
                        currentFileId === index
                          ? 'border-orange-60'
                          : 'border-transparent'
                      )}
                    >
                      <img
                        alt="preview download url"
                        src={fileDetailed.image.preview}
                        className={classNames('w-full')}
                      />
                    </PreviewItem>
                  )}
                </PreviewList>
              </PreviewWrapper>
            )}
            <div
              className={classNames(
                'flex-shrink-0',
                'w-full',
                'sm:w-[344px]',
                'lg:w-[380px]',
                'sm:pr-[35px]'
              )}
            >
              {parseData?.result &&
                <img
                  alt="current file"
                  src={parseData.result[currentFileId].image.original || files?.[0]?.preview}
                  className={classNames('rounded-[8px]')}
                />
              }
            </div>
          </div>
          <div
            className={classNames(
              'w-full',
              'sm:w-[330px]',
              'lg:w-[366px]',
              'flex-shrink-0'
            )}
          >
            {parseData?.result?.length && CORRECT_DOCUMENTS.includes(parseData?.result?.[currentFileId].type) ? (
              <>
                <Headline size={3}>
                  {result.document}
                  {Number(parseData.result?.length) > 1
                    ? ` ${currentFileId + 1}`
                    : ''}
                  : {parseData.result?.[currentFileId].type === 'passport' ?
                    LABELS.passport[locale] :
                    LABELS.snils[locale]}
                </Headline>
                {isValidPassportData(parseData.result?.[currentFileId].type) && (
                  <Passport
                    data={parseData.result?.[currentFileId]}
                  />
                )}
                {isValidSnilsData(parseData.result?.[currentFileId].type) && (
                  <Snils
                    data={parseData.result?.[currentFileId]}
                  />
                )}
                <Price data={result} />
              </>
            ) : (
              <>
                <Headline size={3} className={classNames('mb-[20px]')}>
                  {Number(task?.files?.length) > 1
                    ? `${result.document} ${currentFileId + 1}: `
                    : ''}
                  {result.demoTitle}
                </Headline>
                <div
                  className={classNames(
                    'text-[18px]',
                    'leading-[23px]',
                    'mb-[30px]'
                  )}
                >
                  {result.demoPrefix}{' '}
                  <Link to=""
                    onClick={handleOpenRequestPanel}
                    className={classNames('text-orange-60', 'cursor-pointer')}
                  >
                    {result.sendRequest}
                  </Link>{' '}
                  {result.demoSuffix}
                </div>
              </>
            )}
            <Button onClick={handleReset} name="tryOneMoreRecognition">{statusCode.completed}</Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={classNames('relative', 'xl:flex', 'xl:space-x-[53px]')}>
        <div
          className={classNames(
            'rounded-[4px]',
            'border-[2px]',
            'sm:border-[3px]',
            'lg:border-[5px]',
            'border-dashed',
            error ? 'border-red-90' : 'border-orange-60',
            'min-h-[209px]',
            'sm:min-h-[295px]',
            'lg:min-h-[464px]',
            'xl:min-w-[950px]',
            isDragActive && ['bg-white', 'bg-opacity-[0.07]'],
            'outline-none',
            'relative'
          )}
          {...getRootProps()}
        >
          <div
            className={classNames(
              styles.dragExampleZone,
              'flex',
              'items-center',
              'text-center',
              'justify-center',
              'px-[28px]',
            )}
            onDrop={handleDrop}
          >
            <input {...getInputProps()} />
            <div
              className={classNames(
                'mt-[-10px]',
                'xs:mt-[-6px]',
                'sm:mt-[0px]',
                'sm:text-[18px]',
                'sm:leading-[23px]',
                'lg:text-[20px]',
                'lg:leading-[25px]'
              )}
            >
              <div
                className={classNames(
                  'max-w-[232px]',
                  'sm:max-w-[360px]',
                  'lg:max-w-full',
                  'mx-auto'
                )}
              >
                {isPending || error ? (
                  <NetworkStatus
                    statusCode={statusCode}
                    delay={delay}
                    handleReset={handleReset}
                    error={error}
                    isPending={isPending}
                  />
                ) : (
                  <EmptyDND description={description} />
                )}
              </div>
              {files && <FileList files={files} />}
            </div>
          </div>
        </div>
        <ExampleFiles
          handleDragStart={handleDragStart}
          handlePreviewClick={handleExampleFileClick}
          example={example}
        />
      </div>
    </div>
  );
};
