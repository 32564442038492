import { LOCALES } from '../../locales';
import { FIELD_EMPTY, FIELD_NOT_RECOGNIZABLE } from './constants';
import { ITask } from './task';

export const getDocType = (task: ITask | null, id: string): string => {
  if (task && !Array.isArray(task.solution)) {
    return task.solution[id]?.[0]?.split('/')[0];
  }
  return '';
};

const isPassport = (currentDocType: string): boolean =>
  currentDocType === 'passport';
const isSnils = (currentDocType: string): boolean => currentDocType === 'snils';

export const isValidSnilsData = (
  currentDocType: string,
): boolean => isSnils(currentDocType);

export const isValidPassportData = (
  currentDocType: string,
): boolean => isPassport(currentDocType);

export const calcSeconds = (at: string, finished?: string):string =>
  (((finished ? +new Date(`${finished}Z`) : Date.now()) - +new Date(`${at}Z`)) / 1000).toFixed(2).replace('.', ',');

export const checkSCValue = (value:string, replace = true, locale:keyof typeof LOCALES = 'en'):string => {
  if (value === 'FIELD_EMPTY') return replace ? FIELD_EMPTY[locale] : '';
  if (value === 'FIELD_NOT_RECOGNIZABLE') return replace ? FIELD_NOT_RECOGNIZABLE[locale] : '';
  return value;
};
