import { useAction } from '@reatom/react';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

import { requestPanelActions, SvgIcon } from '../../../components';

type Data = {
  data: {
    recognizeTime: string
    demoTitle: string
    demoPrefix: string
    demoSuffix: string
    document: string
    sendRequest: string
    toSendRequest: string
    price: string
  };
};

export const Price: FC<Data> = ({ data }) => {
  const openRequestPanelAction = useAction(
    requestPanelActions.openRequestPanelAction
  );
  const handleOpenRequestPanel = useCallback(() => {
    openRequestPanelAction();
  }, [openRequestPanelAction]);

  return (
    <div className={classNames(
      'absolute',
      'bottom-[-50px]',
      'xs:bottom-[-15px]',
      'sm:bottom-[10px]',
      'md:bottom-[60px]',
      'right-0',
      'sm:right-[-26px]',
      'md:right-[60px]',
      'w-full',
      'xs:w-[156px]',
      'sm:w-[176px]',
      'text-[14px]',
      'sm:text-[16px]',
      'leading-[20px]'
    )}>
      <div className={classNames(
        'absolute',
        'bottom-full',
        'right-50',
        'transform',
        'translate-x-[-15px]',
        'translate-y-[-15px]',
        'hidden',
        'sm:block'
      )}>
        <SvgIcon name="arrow_price" />
      </div>
      <p className={classNames('text-graphite-60')}>{ data.price }</p>
      <a
        className={classNames('text-orange-60', 'cursor-pointer')}
        onClick={handleOpenRequestPanel}
      >{ data.toSendRequest }</a>
    </div>
  );
};
