import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { Image, SvgIcon } from '../../../components';

type IExampleFiles = {
  example: {
    images: string[];
    description: string;
  };
  handleDragStart: (e: Event, src: string) => void;
  handleDragEnd?: (e?: Event) => void
  handlePreviewClick: (src: string) => void;
};

export const ExampleFiles: FunctionComponent<IExampleFiles> = ({
  example = {},
  handleDragStart,
  handleDragEnd,
  handlePreviewClick,
}) => {
  return (
    <div
      className={classNames(
        'hidden',
        'sm:block',
        'mt-[50px]',
        'lg:mt-[60px]',
        'xl:mt-[0px]',
        'pl-[30px]',
        'lg:pl-[0px]',
        'relative'
      )}
    >
      <div
        className={classNames(
          'absolute',
          'bottom-full',
          'right-full',
          'transform',
          'translate-x-2/4',
          'lg:translate-x-[-26%]',
          'xl:translate-x-[-46px]',
          'translate-y-[14px]',
          'lg:translate-y-[-16px]',
          'xl:translate-y-[-10px]',
          'lg:scale-x-[2]',
          'lg:scale-y-[2]',
          'xl:scale-x-[-2]',
          'lg:rotate-[25deg]',
          'xl:rotate-[-115deg]'
        )}
      >
        <SvgIcon name="rounded_arrow_up" />
      </div>
      <div
        className={classNames(
          'flex',
          'space-x-[13px]',
          'lg:space-x-[23px]',
          'xl:space-x-[0px]',
          'xl:flex-col-reverse'
        )}
      >
        <div
          className={classNames('flex', 'space-x-[10px]', 'lg:space-x-[20px]')}
        >
          {example.images?.map((image) => (
            <Image
              key={image}
              className={classNames(
                'w-[76px]',
                'h-[76px]',
                'lg:w-[132px]',
                'lg:h-[132px]',
                'rounded-[4px]',
                'overflow-hidden'
              )}
              src={image}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onClick={handlePreviewClick}
            />
          ))}
        </div>
        <div
          className={classNames(
            'mt-[-2px]',
            'lg:mt-[-4px]',
            'max-w-[230px]',
            'lg:max-w-[290px]',
            'text-[16px]',
            'leading-[20px]',
            'xl:mb-[30px]',
            'tracking-[-0.3px]',
            'lg:tracking-[-0.5px]',
            'lg:text-[20px]',
            'lg:leading-[25px]'
          )}
        >
          {example.description}
        </div>
      </div>
    </div>
  );
};
