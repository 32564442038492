import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

type IFileList = {
  files: (File & { preview: string })[];
};

export const FileList: FunctionComponent<IFileList> = ({ files }) => {
  return (
    <div
      className={classNames(
        'mt-[25px]',
        'space-x-[13px]',
        'lg:space-x-[23px]',
        'flex',
        'justify-center'
      )}
    >
      {files.map((file) => (
        <div
          key={file.name}
          className={classNames(
            'w-[76px]',
            'h-[76px]',
            'lg:w-[132px]',
            'lg:h-[132px]',
            'rounded-[4px]',
            'bg-cover',
            'bg-center'
          )}
          style={{
            backgroundImage: `url(${file.preview})`,
          }}
        />
      ))}
    </div>
  );
};
