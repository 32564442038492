import classNames from 'classnames';
import { FC } from 'react';

import i18n, { LOCALES } from '../../../locales';
import { LABELS } from '../constants';
import { checkSCValue } from '../utils';
import { Cell, Label, Row, Text } from './elements';

export const Passport: FC<any> = ({ data }) => {
  const solution = data.data;
  const locale = i18n.language as keyof typeof LOCALES;

  return (
    <div className={classNames('w-full', 'pb-[30px]')}>
      {solution.issuer && (
        <Row>
          <Cell>
            <Label>{LABELS.issuer[locale]}</Label>
            <Text>{checkSCValue(solution.issuer, !!1, locale)}</Text>
          </Cell>
        </Row>
      )}
      {(solution.issueDate || solution.issuerCode || solution.number) && (
        <Row>
          {solution.issueDate && (
            <Cell className="w-1/3">
              <Label>{LABELS.issuedate[locale]}</Label>
              <Text>{checkSCValue(solution.issueDate, !!1, locale)}</Text>
            </Cell>
          )}
          {solution.issuerCode && (
            <Cell className="w-[30%]">
              <Label>{LABELS.issuercode[locale]}</Label>
              <Text>{checkSCValue(solution.issuerCode, !!1, locale)}</Text>
            </Cell>
          )}
          {solution.number && solution.series && (
            <Cell>
              <Label>{LABELS.serialnumber[locale]}</Label>
              <Text>
                {checkSCValue(solution.series, !!1, locale)} {checkSCValue(solution.number, !!1, locale)}
              </Text>
            </Cell>
          )}
          {!solution.issueDate && <Cell />}
          {!solution.issuerCode && <Cell />}
          {!solution.number && <Cell />}
        </Row>
      )}
      {(solution.lastName || solution.firstName || solution.middleName) && (
        <Row>
          <Cell>
            <Label>{LABELS.fio[locale]}</Label>
            <Text>
              {[checkSCValue(solution.lastName, !1, locale),
                checkSCValue(solution.firstName, !1, locale),
                checkSCValue(solution.middleName, !1, locale)]
                  .join(' ')
                  .replace(/\s+/g, ' ')}
            </Text>
          </Cell>
        </Row>
      )}
      {(solution.gender || solution.birthDate) && (
        <Row>
          {solution.gender && (
            <Cell className="w-1/3">
              <Label>{LABELS.gender[locale]}</Label>
              <Text>
                {checkSCValue(solution.gender) === solution.gender ?
                  LABELS[solution.gender.includes('МУЖ') ? 'male' : 'female'][locale] :
                  checkSCValue(solution.gender)}
              </Text>
            </Cell>
          )}
          {solution.birthPlace && (
            <Cell>
              <Label>{LABELS.birthdate[locale]}</Label>
              <Text>{checkSCValue(solution.birthDate, !!1, locale)}</Text>
            </Cell>
          )}
          <Cell />
          {(!solution.gender || !solution.birthDate) && <Cell />}
        </Row>
      )}
      {solution.birthPlace && (
        <Row>
          <Cell>
              <Label>{LABELS.birthplace[locale]}</Label>
              <Text>{checkSCValue(solution.birthPlace, !!1, locale)}</Text>
          </Cell>
        </Row>
      )}
    </div>
  );
};
