import classNames from 'classnames';
import { FC } from 'react';

import { SvgIcon } from '../../../components';
import { MAX_FILE_SIZE } from '../constants';

const maxSize = MAX_FILE_SIZE / 1024 / 1024; // max file size in Mb

export type ErrorType = { [K: string]: string };

interface NetworkStatusProps {
  error: ErrorType | null;
  isPending: boolean;
  statusCode: any;
  delay: number;
  handleReset: () => void;
}

export const NetworkStatus: FC<NetworkStatusProps> = ({
  error,
  handleReset,
  isPending,
  statusCode,
  delay,
}) => {
  return (
    <>
      <div
        onClick={handleReset}
        className={classNames(
          'absolute',
          'opacity-40',
          'top-[28px]',
          'right-[28px]',
          'cursor-pointer'
        )}
      >
        <SvgIcon name="close" className={classNames('w-[24px]', 'h-[24px]')} />
      </div>
      <div className={classNames('flex', 'flex-col', 'items-center')}>
        <div className={classNames(isPending ? 'animate-spin' : 'text-red-90')}>
          <SvgIcon
            name={isPending ? 'spinner' : 'warning'}
            className={classNames('w-[48px]', 'h-[48px]')}
          />
        </div>
        <div
          className={classNames(
            'mt-[25px]',
            error?.title && ['font-bold', 'text-[24px]', 'leading-[28px]']
          )}
        >
          {isPending ? statusCode.pending : error?.title}
        </div>
        {error?.description && +error?.code !== 429 && (
          <div className={classNames('mt-[8px]')}>{error?.description.replace(/%maxSize%/, maxSize.toString())}</div>
        )}
        {error?.description && +error?.code === 429 && delay > 0 && (
          <div className={classNames('mt-[8px]')}>
            {error?.description.replace(/%delay%/, delay.toString())}
          </div>
        )}
        {error?.action && (+error?.code !== 429 || delay <= 0) && (
          <div
            className={classNames(
              'mt-[25px]',
              'text-orange-60',
              'hover:underline',
              'cursor-pointer'
            )}
          >
            <span onClick={handleReset}>{error?.action}</span>
          </div>
        )}
      </div>
    </>
  );
};
