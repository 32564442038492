import { LOCALES } from '../../locales';

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20mb in bytes

export const FIELD_EMPTY = {
  en: 'Field empty',
  ru: 'Поле пустое'
};

export const FIELD_NOT_RECOGNIZABLE = {
  en: 'Field is not recognizable',
  ru: 'Поле не распознается'
};

export const LABELS:Record<string, Record<keyof typeof LOCALES, string>> = {
  passport: {
    en: 'Russian passport',
    ru: 'Паспорт РФ'
  },
  snils: {
    en: 'Insurance ID',
    ru: 'СНИЛС'
  },
  number: {
    en: 'Number',
    ru: 'Номер'
  },
  serial: {
    en: 'Serial',
    ru: 'Серия'
  },
  serialnumber: {
    en: 'Serial & number',
    ru: 'Серия и номер'
  },
  fio: {
    en: 'Full name',
    ru: 'ФИО'
  },
  gender: {
    en: 'Gender',
    ru: 'Пол'
  },
  male: {
    en: 'Male',
    ru: 'Мужской'
  },
  female: {
    en: 'Female',
    ru: 'Женский'
  },
  birthdate: {
    en: 'Birth date',
    ru: 'Дата рождения'
  },
  birthplace: {
    en: 'Birth place',
    ru: 'Место рождения'
  },
  issuer: {
    en: 'Issuer',
    ru: 'Паспорт выдан'
  },
  issuedate: {
    en: 'Issue date',
    ru: 'Дата выдачи'
  },
  issuercode: {
    en: 'Issuer code',
    ru: 'Код подр.'
  }
};