import classNames from 'classnames';
import React, { FC } from 'react';

interface IEmptyDND {
  description: {
    desktop: {
      text: string;
      link: string;
    };
    mobile: {
      text: string;
      link: string;
    };
  };
}

export const EmptyDND: FC<IEmptyDND> = ({ description }) => (
  <>
    <span className={classNames('sm:hidden', 'cursor-pointer')}>
      {description?.mobile?.text}
      <span className={classNames('text-orange-60')}>
        {description?.mobile?.link}
      </span>
    </span>
    <span className={classNames('hidden', 'sm:inline', 'cursor-pointer')}>
      {description?.desktop?.text}
      <span className={classNames('text-orange-60')}>
        {description?.desktop?.link}
      </span>
    </span>
  </>
);
