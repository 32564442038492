import classNames from 'classnames';
import { FC } from 'react';

import i18n, { LOCALES } from '../../../locales';
import { LABELS } from '../constants';
import { checkSCValue } from '../utils';
import { Cell, Label, Row, Text } from './elements';

export const Snils: FC<any> = ({ data }) => {
  const solution = data.data;
  const locale = i18n.language as keyof typeof LOCALES;

  return (
    <div className={classNames('w-full', 'pb-[10px]')}>
      {solution.snils && (
        <Row>
          <Cell>
            <Label>{LABELS.number[locale]}</Label>
            <Text>{checkSCValue(solution.snils, !!1, locale)}</Text>
          </Cell>
        </Row>
      )}
      {solution.fullName && (
        <Row>
          <Cell>
            <Label>{LABELS.fio[locale]}</Label>
            <Text>{checkSCValue(solution.fullName, !!1, locale)}</Text>
          </Cell>
        </Row>
      )}
      {(solution.gender || solution.birthDate) && (
        <Row>
          {solution.gender && (
            <Cell>
              <Label>{LABELS.gender[locale]}</Label>
              <Text>
                {checkSCValue(solution.gender) === solution.gender ?
                  LABELS[solution.gender.includes('МУЖ') ? 'male' : 'female'][locale] :
                  checkSCValue(solution.gender)}
              </Text>
            </Cell>
          )}
          {solution.birthDate && (
            <Cell>
              <Label>{LABELS.birthdate[locale]}</Label>
              <Text>{checkSCValue(solution.birthDate, !!1, locale)}</Text>
            </Cell>
          )}
          <Cell />
          {(!solution.gender || !solution.birthDate) && <Cell />}
        </Row>
      )}
      {solution.birthPlace && (
        <Row>
          <Cell>
            <Label>{LABELS.birthplace[locale]}</Label>
            <Text>{checkSCValue(solution.birthPlace, !!1, locale)}</Text>
          </Cell>
        </Row>
      )}
    </div>
  );
};
