import classNames from 'classnames';
import { FC } from 'react';

import { Trans } from '../../components';
import { useTranslateData } from '../../hooks';
import { Headline } from '../../ui';
import { DND } from './partials';

export const LetUsTry: FC = (props) => {
  const { id, title } = useTranslateData('sections.letUsTry');

  return (
    <section id={id} {...props}>
      <div className="bg-graphite-90">
        <div
          className={classNames(
            'container',
            'pt-[40px]',
            'sm:pt-[65px]',
            'lg:pt-[120px]',
            'pb-[61px]',
            'sm:pb-[71px]',
            'lg:pb-[150px]',
            'text-white'
          )}
        >
          <Headline
            size={2}
            className={classNames(
              'text-center',
              'sm:text-left',
              'mb-[27px]',
              'xs:mb-[29px]',
              'sm:mb-[54px]',
              'lg:mb-[70px]'
            )}
          >
            <Trans>{title}</Trans>
          </Headline>
          <DND />
        </div>
      </div>
    </section>
  );
};
