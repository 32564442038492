const isSnils = (name:string) => name === 'snils';

export const parseSolution = (data:IData):IParseData => {
  const { status, createdAt, metrics } = data;
  const parseData:IParseData = {
    status,
    createdAt,
    finishedAt: metrics?.find(el => el.name === 'TaskCompleted')?.createdAt || null,
    result: []
  };

  try {
    const keysExplainedDocs = Object.keys(data.solution).filter((key) => key.startsWith('document_'));
    keysExplainedDocs.forEach((key, indexKeyDoc) => {
      (data.solution[key] as string[]).forEach((typeDoc) => {
        const [doc, page] = typeDoc.split('/');

        const item = {
          type: doc,
          id: data.files[indexKeyDoc]?.preview.id,
          image: {
            original: data.files[indexKeyDoc]?.original.downloadUrl,
            preview: data.files[indexKeyDoc]?.preview.downloadUrl,
          },
        };
        if (doc === 'unknown') {
          parseData.result.push(item);
          return;
        }
        if (!page) return;

        const documentExplained = data.solution.explained[doc];
        if (documentExplained.present) { //file parsed
          parseData.result.push({
            ...item,
            data: isSnils(doc) ? (documentExplained.pages[0] as IExplainedDoc) : documentExplained,
          });
        }
      });
    });
  } catch (e) {
    console.warn(e);
  }

  return parseData;
};