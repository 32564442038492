import { useAction } from '@reatom/react';
import classNames from 'classnames';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { FC, useCallback } from 'react';

import { requestPanelActions, Trans } from '../../components';
import { useBreakpoint, useTranslateData } from '../../hooks';
import { Button, Headline, Link } from '../../ui';

type IMainFeaturedData = {
  title: string;
  description: string;
};

type ILetUsTry = {
  id: string;
  title: string;
};

type IControls = {
  request: string;
  demo: string;
  determineValue: string;
  more: string;
};

const breakpoints = ['lg', 'xl'];

export const MainFeatured: FC = (props) => {
  const { title, description } = useTranslateData<IMainFeaturedData>(
    'sections.mainFeatured'
  ) as IMainFeaturedData;
  const { id: letUsTryId } = useTranslateData<ILetUsTry>(
    'sections.letUsTry'
  ) as ILetUsTry;
  const { request, demo } = useTranslateData<IControls>(
    'controls'
  ) as IControls;
  const { scrollYProgress } = useViewportScroll();
  const { breakpoint } = useBreakpoint();
  const openRequestPanelAction = useAction(
    requestPanelActions.openRequestPanelAction
  );

  const handleOpenRequestPanel = useCallback(() => {
    openRequestPanelAction();
  }, [openRequestPanelAction]);

  const opacity = useTransform(scrollYProgress, (v) => {
    const realValue = 5 - 200 * v;
    const max = 1;
    const min = 0;

    if (!breakpoints.includes(breakpoint as string)) {
      return max;
    }

    return realValue <= min ? min : realValue >= max ? max : realValue;
  });

  return (
    <section {...props}>
      <motion.div
        style={{ opacity }}
        className={classNames(
          'container',
          'pt-[24px]',
          'sm:pt-[50px]',
          'lg:pt-[119px]',
          'xl:pt-[118px]',
          'pb-[167px]',
          'xs:pb-[163px]',
          'sm:pb-[298px]',
          'lg:pb-[380px]',
          'xl:pb-[376px]'
        )}
      >
        <Headline
          className={classNames(
            'mb-[9px]',
            'xs:mb-[17px]',
            'sm:mb-[19px]',
            'lg:mb-[24px]',
            'xl:mb-[18px]'
          )}
        >
          <Trans>{title}</Trans>
        </Headline>
        <div
          className={classNames(
            'mb-[33px]',
            'xs:mb-[29px]',
            'sm:mb-[42px]',
            'lg:mb-[51px]',
            'lg:max-w-[940px]'
          )}
        >
          <Trans>{description}</Trans>
        </div>
        <div
          className={classNames('flex', 'space-x-[14px]', 'sm:space-x-[20px]')}
        >
          <Button
            className={classNames(
              'flex-shrink',
              'w-[150px]',
              'sm:w-min',
              'lg:min-w-[210px]'
            )}
            onClick={handleOpenRequestPanel}
            name="openRequestPanel"
          >
            <Trans>{request}</Trans>
          </Button>
          <Link to={`/#${letUsTryId}`}>
            <Button
              colored={false}
              className={classNames('w-[124px]', 'sm:w-min', 'flex-shrink')}
              name="tryRecognition"
            >
              <Trans>{demo}</Trans>
            </Button>
          </Link>
        </div>
      </motion.div>
    </section>
  );
};
