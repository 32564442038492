export const getImageAsUri = async (
  src: string,
  clb: (args: File[]) => void,
  files: File[] = []
): Promise<void> => {
  const response = await fetch(src);
  if (response.status === 200) {
    const blob = await response.blob();
    const file = new File([blob], response.url.match(/^.+\/([^/]+)$/)[1], {
      type: 'image/jpeg',
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      file.src = reader.result;
      clb(files.concat(file));
    };
    reader.readAsDataURL(file);
  }
};
