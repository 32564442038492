import classNames from 'classnames';
import { FC, LiHTMLAttributes, ReactNode } from 'react';

interface IPreviewList {
  children: ReactNode;
}

interface IPreviewItem extends LiHTMLAttributes<HTMLLIElement> {
  children: ReactNode;
}

export const Label: FC = ({ children }) => {
  return (
    <label
      className={classNames(
        'text-[16px]',
        'leading-[20px]',
        'block',
        'mt-[20px]',
        'tracking-[-0.2px]',
        'text-graphite-60'
      )}
    >
      {children}
    </label>
  );
};

export const Text: FC = ({ children }) => {
  return (
    <span
      className={classNames(
        'text-[16px]',
        'lg:text-[18px]',
        'leading-[20px]',
        'lg:leading-[23px]',
        'tracking-[-0.55px]'
      )}
    >
      {children}
    </span>
  );
};

export const Row: FC = ({ children }) => {
  return (
    <div className={classNames('flex', 'w-full', 'flex-wrap')}>{children}</div>
  );
};

export const Cell: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        'pr-[16px]',
        'lg:pr-[20px]',
        'min-w-[50%]',
        'sm:min-w-0',
        className
      )}
    >
      {children}
    </div>
  );
};

export const PreviewWrapper: FC = ({ children }) => (
  <div
    className={classNames(
      'pt-[20px]',
      'pr-[35px]',
      'lg:pt-0',
      'lg:pr-[15px]',
      'lg:max-h-[425px]',
      'overflow-hidden'
    )}
  >
    {children}
  </div>
);

export const PreviewList: FC<IPreviewList> = ({ children }) => (
  <ul
    className={classNames('h-full', 'pr-[20px]', 'mr-[-32px]', 'overflow-auto')}
  >
    {children}
  </ul>
);

export const PreviewItem: FC<IPreviewItem> = ({
  className,
  children,
  ...props
}) => (
  <li
    className={classNames(
      'w-[70px]',
      'h-[70px]',
      'lg:w-[96px]',
      'lg:h-[96px]',
      'float-left',
      'lg:float-none',
      'rounded-[4px]',
      'overflow-hidden',
      'object-cover',
      'lg:mb-[14px]',
      'mr-[14px]',
      'border-[3px]',
      className
    )}
    {...props}
  >
    {children}
  </li>
);
